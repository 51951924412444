import {
  quopayExchangePoint
} from '@/services/quopay.service'
const initialState = {
  referenceNumber: null
}

export const state = { ...initialState }

const getters = {}

const mutations = {}

const actions = {
  quopayExchangePoint ({ commit }, params = {}) {
    return quopayExchangePoint(params).then(res => {
      return res
    }).catch(err => {
      return err.response.data
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
