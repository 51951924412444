import {
  afadRequest
} from '@/services/afad.service'
const initialState = {
}

export const state = { ...initialState }

const getters = {}

const mutations = {}

const actions = {
  userAFADRequest ({ commit }, params = {}) {
    return afadRequest(params).then(res => {
      return res
    }).catch(err => {
      return err.response.data
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
