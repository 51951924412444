import { ApiService } from './api.service'

const BaseUrl = '/exchange-service'

export function exchangeGpoint (params = {}) {
  return ApiService.post(`${BaseUrl}/g-point`, params).then(resp => resp.data)
}

export function exchangeGiftPad (params = {}) {
  return ApiService.post(`${BaseUrl}/gift-pad`, params).then(resp => resp.data)
}

export function exchangeDigitalGift (params = {}) {
  return ApiService.post(`${BaseUrl}/digital-gift`, params).then(resp => resp.data)
}
