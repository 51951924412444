import { EXCHANGE_POINT_SERVICE } from '@/enum/exchange-service'

export function getExchangeServices () {
  return Object.values(EXCHANGE_POINT_SERVICE)
}

export function getExchangeServiceRoutes () {
  return getExchangeServices().map((service) => service.route)
}

export function getExchangeServiceNames () {
  return getExchangeServices().map((service) => service.name)
}
