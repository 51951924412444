import { ApiService } from './api.service'
import { getOneTimeParams } from '@/utils/helpers'

const BaseUrl = '/carts'

const payload = (params) => ({
  ...params,
  ...getOneTimeParams()
})

export function getListCart (params = {}) {
  return ApiService.get(`${BaseUrl}`, payload(params)).then(resp => resp)
}

export function getCartDetail (id) {
  return ApiService.get(`${BaseUrl}/${id}`, {}).then((resp) => resp)
}

export function deleteCart (id) {
  return ApiService.delete(`${BaseUrl}/${id}`, {}).then((resp) => resp)
}

export function createCart (params = {}) {
  return ApiService.post(`${BaseUrl}`, payload(params)).then(resp => resp)
}

export function updateCart (id, params = {}) {
  return ApiService.put(`${BaseUrl}/${id}`, payload(params)).then(resp => resp)
}
