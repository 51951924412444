import store from '@/store'

export const LoginGuard = async (to, from, next) => {
  if (!store.state.auth.token || to.name === 'RegisterSNS' || to.name === 'RegisterComplete') return next()
  if (!to.query.callback) return next({ name: 'Index' })

  const searchParams = new URL(to.query.callback).searchParams
  const loginFlag = searchParams.get('login_flag')
  const callbackState = searchParams.get('callbackstate')

  if (loginFlag !== '1' || callbackState == null) return next({ name: 'Index' })

  const confirmExchange = await store.dispatch('dotMoney/confirmExchange')
  const confirmStatus = confirmExchange?.data?.status_code

  if (confirmStatus === 200) {
    const loginDotMoney = await store.dispatch('dotMoney/loginDotMoney')
    window.open(loginDotMoney.data, '_self')
  } else if (confirmStatus === 404) {
    window.open(to.query.callback, '_self')
  }

  next()
}
