import { ApiService } from './api.service'

const BaseUrl = '/d-money'

export function confirmExchange () {
  return ApiService.get(`${BaseUrl}/confirm`).then((resp) => resp.data)
}

export function authDotMoney () {
  return ApiService.get(`${BaseUrl}/authenticate`).then((resp) => resp)
}

export function moneyExchange (param = {}) {
  return ApiService.post(`${BaseUrl}/exchange`, param).then((resp) => resp.data)
}

export function loginDotMoney () {
  return ApiService.get(`${BaseUrl}/login`).then((resp) => resp)
}
