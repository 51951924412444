
import { EXCHANGE_POINT_SERVICE } from '@/enum/exchange-service'

export const HEADER_TITLE = [
  {
    router: 'CategoryList.Category',
    title: 'カテゴリー一覧',
    pattern: 'D',
    flag_authen: false
  },
  {
    router: 'CategoryList.Search',
    title: '検索結果',
    pattern: 'D',
    flag_authen: false
  },
  {
    router: 'CategoryList.Receipt',
    title: 'レシート一覧',
    pattern: 'D',
    flag_authen: false
  },
  {
    router: 'CategoryList.Survey',
    title: 'アンケート・モニター',
    pattern: 'D',
    flag_authen: false
  },
  {
    router: 'AdvertisingDetail',
    title: 'プログラム詳細',
    pattern: 'B',
    flag_authen: true
  },
  {
    router: 'FeatureList',
    title: '特集',
    pattern: 'D',
    flag_authen: false
  },
  {
    router: 'InviteFriend',
    title: 'お友達紹介特典',
    pattern: 'D',
    flag_authen: true
  },
  {
    router: 'ContactForm',
    title: 'お問い合わせ',
    pattern: 'B',
    flag_authen: true
  },
  {
    router: 'ContactComplete',
    title: 'お問い合わせ',
    pattern: 'D',
    flag_authen: true
  },
  {
    router: 'ForgotPassword',
    title: 'パスワードをお忘れの方',
    pattern: 'B',
    flag_authen: false
  },
  {
    router: 'ChangePassword',
    title: 'パスワード変更',
    pattern: 'C',
    flag_authen: false
  },
  {
    router: 'Profile.News.List',
    title: 'お知らせ',
    pattern: 'B',
    flag_authen: false
  },
  {
    router: 'Profile.News.Detail',
    title: 'お知らせ',
    pattern: 'B',
    flag_authen: false
  },
  {
    router: 'Profile.FavoriteAds',
    title: 'お気に入り',
    pattern: 'B',
    flag_authen: false
  },
  {
    router: 'Profile.RecentlySeenAds',
    title: '広告閲覧履歴',
    pattern: 'D',
    flag_authen: false
  },
  {
    router: 'Profile.CartList',
    title: '買うものリスト',
    pattern: 'B',
    flag_authen: false
  },
  {
    router: 'ChangeEmail',
    title: 'メールアドレス変更',
    pattern: 'B',
    flag_authen: false
  },
  {
    router: 'ChangeEmailComplete',
    title: 'メールアドレス変更',
    pattern: 'C',
    flag_authen: false
  },
  {
    router: 'ChangeProfile',
    title: '登録情報変更',
    pattern: 'B',
    flag_authen: false
  },
  {
    router: 'Profile.MailSetting',
    title: 'メール配信設定',
    pattern: 'B',
    flag_authen: false
  },
  {
    router: 'Profile.DeleteUser',
    title: '退会',
    pattern: 'B',
    flag_authen: false
  },
  {
    router: 'DeleteUserComplete',
    title: '退会',
    pattern: 'C',
    flag_authen: false
  },
  {
    router: 'Guide',
    title: 'ご利用ガイド',
    pattern: 'B',
    flag_authen: false
  },
  {
    router: 'Help',
    title: 'ヘルプ',
    pattern: 'B',
    flag_authen: false
  },
  {
    router: 'AboutAdvertising',
    title: '広告掲載について',
    pattern: 'B',
    flag_authen: false
  },
  {
    router: 'Policy',
    title: '個人情報の取扱いについて',
    pattern: 'B',
    flag_authen: false
  },
  {
    router: 'Term',
    title: '利用規約',
    pattern: 'B',
    flag_authen: false
  },
  {
    router: 'ExchangePoint',
    title: 'ポイント交換先一覧',
    pattern: 'B',
    flag_authen: false
  },
  {
    router: EXCHANGE_POINT_SERVICE.dotMoney.route,
    title: 'ポイント交換先詳細',
    pattern: 'B',
    flag_authen: true
  },
  {
    router: EXCHANGE_POINT_SERVICE.gPoint.route,
    title: 'ポイント交換先詳細',
    pattern: 'B',
    flag_authen: true
  },
  {
    router: EXCHANGE_POINT_SERVICE.giftPad.route,
    title: 'ポイント交換先詳細',
    pattern: 'B',
    flag_authen: true
  },
  {
    router: EXCHANGE_POINT_SERVICE.linePay.route,
    title: 'ポイント交換先詳細',
    pattern: 'B',
    flag_authen: true
  },
  {
    router: EXCHANGE_POINT_SERVICE.quoPay.route,
    title: 'ポイント交換先詳細',
    pattern: 'B',
    flag_authen: true
  },
  {
    router: EXCHANGE_POINT_SERVICE.auPay.route,
    title: 'ポイント交換先詳細',
    pattern: 'B',
    flag_authen: true
  },
  {
    router: EXCHANGE_POINT_SERVICE.yoshinoya.route,
    title: 'ポイント交換先詳細',
    pattern: 'B',
    flag_authen: true
  },
  {
    router: EXCHANGE_POINT_SERVICE.kfc.route,
    title: 'ポイント交換先詳細',
    pattern: 'B',
    flag_authen: true
  },
  {
    router: EXCHANGE_POINT_SERVICE.tully.route,
    title: 'ポイント交換先詳細',
    pattern: 'B',
    flag_authen: true
  },
  {
    router: 'Profile.Mypage',
    title: 'マイページ',
    pattern: 'D',
    flag_authen: false
  },
  {
    router: 'Profile.PointExchangeHistory',
    title: 'ポイント交換詳細',
    pattern: 'B',
    flag_authen: false
  },
  {
    router: 'Register',
    title: '新規会員登録',
    pattern: 'B',
    flag_authen: false
  },
  {
    router: 'Login',
    title: 'ログイン',
    pattern: 'B',
    flag_authen: false
  },
  {
    router: 'Index',
    title: '',
    pattern: 'A',
    flag_authen: false
  },
  {
    router: 'LandingPage',
    title: 'LP',
    pattern: 'B',
    flag_authen: true
  }
]

export const PAGE_NATIVE = [
  {
    path: '/login',
    name: 'Login'
  },
  {
    path: '/registration',
    name: 'Register'
  },
  {
    path: '/',
    name: 'Index'
  },
  {
    path: '/profile/mypage',
    name: 'Profile.Mypage'
  },
  {
    path: '/profile/point-exchange-history',
    name: 'Profile.PointExchangeHistory'
  },
  {
    path: '/profile/recently-seen-ads',
    name: 'Profile.RecentlySeenAds'
  }
]
