import { ApiService } from './api.service'

const BaseUrl = '/news'

export function getListNews (params = {}) {
  return ApiService.get(`${BaseUrl}`, params).then(resp => resp)
}

export function getNewsDetail (id) {
  return ApiService.get(`${BaseUrl}/${id}`).then(resp => resp)
}
