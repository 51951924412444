import { AuthGuard, LoginGuard, ResolveGuard, ExchangePointGuard } from '@/router/guards'
import { EXCHANGE_POINT_SERVICE } from '@/enum/exchange-service'

export default [
  // Main
  {
    path: '/',
    name: 'Index',
    component: () => import('@/views/Index.vue')
  },

  // Login
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/auth/Login'),
    beforeEnter: ResolveGuard([LoginGuard])
  },

  // Forget Password
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/views/auth/ForgetPassword')
  },

  // Change Password
  {
    path: '/reset-password/:token',
    name: 'ChangePassword',
    component: () => import('@/views/auth/ChangePassword')
  },

  // Category list
  {
    path: '/category',
    redirect: '/category/:id',
    component: () => import('@/views/CategoryList/Index'),
    children: [
      {
        path: '/category/:id',
        name: 'CategoryList.Category',
        component: () => import('@/views/CategoryList/Category')
      },
      {
        path: '/survey/:id',
        name: 'CategoryList.Survey',
        component: () => import('@/views/CategoryList/Survey')
      },
      {
        path: '/receipt/:id',
        name: 'CategoryList.Receipt',
        component: () => import('@/views/CategoryList/Receipt')
      },
      {
        path: '/search',
        name: 'CategoryList.Search',
        component: () => import('@/views/CategoryList/SearchResult')
      }
    ]
  },

  // Register
  {
    path: '/registration',
    component: () => import('@/views/Register.vue'),
    beforeEnter: ResolveGuard([LoginGuard]),
    children: [
      {
        path: '',
        name: 'Register',
        component: () => import('@/views/Register/Step1.vue')
      },
      {
        path: '/registration/:service/callback',
        name: 'RegisterSNS',
        component: () => import('@/views/Register/StepSNS.vue')
      },
      {
        path: '/registration/form/:token',
        name: 'RegisterForm',
        component: () => import('@/views/Register/Step2.vue')
      },
      {
        path: '/registration/complete',
        name: 'RegisterComplete',
        component: () => import('@/views/Register/Step3.vue')
      }
    ]
  },

  // Advertising detail
  {
    path: '/advertising-detail/:id',
    name: 'AdvertisingDetail',
    component: () => import('@/views/AdvertisingDetail.vue')
  },

  // Profile
  {
    path: '/profile',
    redirect: '/profile/mypage',
    component: () => import('@/views/Profile/Index.vue'),
    beforeEnter: ResolveGuard([AuthGuard]),
    children: [
      {
        path: '/profile/mypage',
        name: 'Profile.Mypage',
        component: () => import('@/views/Profile/PointExchange.vue')
      },
      {
        path: '/profile/news-list/',
        name: 'Profile.News.List',
        component: () => import('@/views/Profile/NewsList.vue')
      },
      {
        path: '/profile/news-list/detail/:id',
        name: 'Profile.News.Detail',
        component: () => import('@/views/Profile/NewsDetail.vue')
      },
      {
        path: '/profile/favorite-ads',
        name: 'Profile.FavoriteAds',
        component: () => import('@/views/Profile/FavoriteAds.vue')
      },
      {
        path: '/profile/point-exchange-history',
        name: 'Profile.PointExchangeHistory',
        component: () => import('@/views/Profile/PointExchangeHistory.vue')
      },
      {
        path: '/profile/recently-seen-ads',
        name: 'Profile.RecentlySeenAds',
        component: () => import('@/views/Profile/RecentlySeenAds.vue')
      },
      {
        path: '/profile/cart-list',
        name: 'Profile.CartList',
        component: () => import('@/views/Profile/CartList.vue')
      },
      {
        path: '/profile/delete-user',
        name: 'Profile.DeleteUser',
        component: () => import('@/views/Profile/DeleteUser')
      },
      {
        path: '/profile/mail-setting',
        name: 'Profile.MailSetting',
        component: () => import('@/views/Profile/MailSetting')
      }
    ]
  },

  // Change Profile
  {
    path: '/change-profile',
    name: 'ChangeProfile',
    component: () => import('@/views/Profile/ChangeProfile.vue'),
    beforeEnter: ResolveGuard([AuthGuard])
  },

  // Invite friend
  {
    path: '/invite-friend',
    name: 'InviteFriend',
    component: () => import('@/views/InviteFriend.vue')
  },

  // expired page
  {
    path: '/expired',
    name: 'Expired',
    component: () => import('@/views/PageError/PageExpired')
  },

  // Feature list
  {
    path: '/feature-list',
    name: 'FeatureList',
    component: () => import('@/views/FeatureList.vue')
  },

  // Contact
  {
    path: '/contact',
    component: () => import('@/views/Contact/Index.vue'),
    children: [
      {
        path: '',
        name: 'ContactForm',
        component: () => import('@/views/Contact/Form.vue')
      },
      {
        path: '/contact/complete',
        name: 'ContactComplete',
        component: () => import('@/views/Contact/Complete.vue')
      }
    ]
  },

  // Change Mail
  {
    path: '/change-email',
    name: 'ChangeEmail',
    component: () => import('@/views/ChangeEmail/ChangeEmail.vue'),
    beforeEnter: ResolveGuard([AuthGuard])
  },

  {
    path: '/change-email/complete/:token',
    name: 'ChangeEmailComplete',
    component: () => import('@/views/ChangeEmail/Complete'),
    beforeEnter: ResolveGuard([AuthGuard])
  },

  // Exchange point
  {
    path: '/exchange-point',
    name: 'ExchangePoint',
    component: () => import('@/views/ExchangePoint/Index')
  },
  {
    path: '/exchange-service/dotmoney',
    name: EXCHANGE_POINT_SERVICE.dotMoney.route,
    component: () => import('@/views/ExchangePoint/dotmoney/Index'),
    beforeEnter: ResolveGuard([ExchangePointGuard])
  },

  // Callback confirm account from dot money --> redirect to dot-money service
  {
    path: '/exchange-service/dotmoney/callback',
    redirect: '/exchange-service/dotmoney'
  },
  {
    path: '/exchange-service/gpoint',
    name: EXCHANGE_POINT_SERVICE.gPoint.route,
    component: () => import('@/views/ExchangePoint/gpoint/Index'),
    beforeEnter: ResolveGuard([ExchangePointGuard])
  },
  {
    path: '/exchange-service/giftpad',
    name: EXCHANGE_POINT_SERVICE.giftPad.route,
    component: () => import('@/views/ExchangePoint/giftpad/Index'),
    beforeEnter: ResolveGuard([ExchangePointGuard])
  },
  {
    path: '/exchange-service/linepay',
    name: EXCHANGE_POINT_SERVICE.linePay.route,
    component: () => import('@/views/ExchangePoint/linepay/Index'),
    beforeEnter: ResolveGuard([ExchangePointGuard])
  },
  {
    path: '/exchange-service/quopay',
    name: EXCHANGE_POINT_SERVICE.quoPay.route,
    component: () => import('@/views/ExchangePoint/quopay/Index'),
    beforeEnter: ResolveGuard([ExchangePointGuard])
  },
  {
    path: '/exchange-service/aupay',
    name: EXCHANGE_POINT_SERVICE.auPay.route,
    component: () => import('@/views/ExchangePoint/aupay/Index'),
    beforeEnter: ResolveGuard([ExchangePointGuard])
  },
  {
    path: '/exchange-service/yoshinoya',
    name: EXCHANGE_POINT_SERVICE.yoshinoya.route,
    component: () => import('@/views/ExchangePoint/yoshinoya/Index'),
    beforeEnter: ResolveGuard([ExchangePointGuard])
  },
  {
    path: '/exchange-service/kfc',
    name: EXCHANGE_POINT_SERVICE.kfc.route,
    component: () => import('@/views/ExchangePoint/kfc/Index'),
    beforeEnter: ResolveGuard([ExchangePointGuard])
  },
  {
    path: '/exchange-service/tully',
    name: EXCHANGE_POINT_SERVICE.tully.route,
    component: () => import('@/views/ExchangePoint/tully/Index'),
    beforeEnter: ResolveGuard([ExchangePointGuard])
  },
  {
    path: '/exchange-service/movie-gift',
    name: EXCHANGE_POINT_SERVICE.movieWalker.route,
    component: () => import('@/views/ExchangePoint/movieWalker/Index'),
    beforeEnter: ResolveGuard([ExchangePointGuard])
  },

  // Gift code
  {
    path: '/gift-code-input',
    name: 'GiftCodeInput',
    component: () => import('@/views/GiftCode/GiftCodeInput.vue'),
    beforeEnter: ResolveGuard([AuthGuard])
  },
  {
    path: '/gift-code/:code',
    name: 'GiftCodeDetail',
    component: () => import('@/views/GiftCode/GiftCodeDetail.vue')
  },
  {
    path: '/gift-code-error',
    name: 'GiftCodeError',
    component: () => import('@/views/GiftCode/GiftCodeError.vue')
  },
  {
    path: '/gift-code-get/:id',
    name: 'GiftCodeGet',
    component: () => import('@/views/GiftCode/GiftCodeGet.vue'),
    beforeEnter: ResolveGuard([AuthGuard])
  },
  {
    path: '/gift-code-complete/:id',
    name: 'GiftCodeComplete',
    component: () => import('@/views/GiftCode/GiftCodeComplete.vue'),
    beforeEnter: ResolveGuard([AuthGuard])
  },

  // Delete user complete
  {
    path: '/delete-user/complete',
    name: 'DeleteUserComplete',
    component: () => import('@/views/DeleteUserComplete')
  },

  // Call back twitter
  {
    path: 'share/twitter/callback',
    name: 'Twitter.Callback',
    component: () => import('@/views/share/twitter/Callback')
  },

  // About advertising
  {
    path: '/about-advertising',
    name: 'AboutAdvertising',
    component: () => import('@/pages/About')
  },

  // Guide
  {
    path: '/guide',
    name: 'Guide',
    component: () => import('@/pages/Guide')
  },

  // Help
  {
    path: '/help',
    name: 'Help',
    component: () => import('@/pages/Help')
  },

  // Policy
  {
    path: '/policy',
    name: 'Policy',
    component: () => import('@/pages/Policy.vue')
  },

  // Policy
  {
    path: '/term',
    name: 'Term',
    component: () => import('@/pages/Term.vue')
  },

  // LandingPage
  {
    path: '/lp/*',
    name: 'LandingPage',
    component: () => import('@/views/LandingPage/Lp.vue')
  },

  // Page Not Found ---> REMEMBER THIS ROUTE ALWAYS PUT LAST
  {
    path: '*',
    name: 'PageNotFound',
    component: () => import('@/views/PageError/404.vue')
  }
]
