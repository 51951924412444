import {
  getListNews,
  getNewsDetail
} from '@/services/news.service'

const initialState = {
  newsList: [],
  pagination: {},
  newsDetail: {}
}

export const state = { ...initialState }

const getters = {}

const mutations = {
  SET_LIST (state, payload) {
    state.newsList = payload
  },

  SET_PAGINATION (state, payload) {
    state.pagination = payload
  },

  SET_NEWS_DETAIL (state, payload) {
    state.newsDetail = payload
  }
}

const actions = {
  getListNews ({ commit, dispatch }, params = {}) {
    return getListNews(params).then((res) => {
      commit('SET_LIST', res.data.data)
      if (res.data.pagination) {
        commit('SET_PAGINATION', res.data.pagination)
      }
      return res
    }).catch(err => {
      return err.response.data
    })
  },

  getNewsDetail ({ commit, dispatch }, id) {
    return getNewsDetail(id).then((res) => {
      commit('SET_NEWS_DETAIL', res.data.data)
      return true
    }).catch(() => {
      return false
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
