import Cookie from 'js-cookie'
import sanitizeHtml from 'sanitize-html'
import moment from 'moment'
import { dohBoParamPrefix, oneTimeParamPrefix } from '@/enum/dohbo'

export const handleSanitizeHtml = (html) => {
  const optionSanitize = {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
    allowedAttributes: {
      '*': ['href', 'align', 'alt', 'center', 'border', 'src', 'style']
    },
    enforceHtmlBoundary: true
  }

  return sanitizeHtml(html, optionSanitize)
}

export const checkWebview = () => {
  return !!((checkAndroid() || checkIOS()))
}

export const checkAndroid = () => {
  const userAgent = window.navigator.userAgent.toLowerCase()
  return !!userAgent.includes('wv')
}

export const checkIOS = () => {
  const userAgent = window.navigator.userAgent.toLowerCase()
  const safari = /safari/.test(userAgent)
  const ios = /iphone|ipod|ipad/.test(userAgent)
  return !!((ios && !safari))
}

export const triggerEventApp = (payload) => {
  if (checkAndroid()) {
    window.android.triggerEvent(JSON.stringify({
      event: event,
      payload: payload
    }))
  } else if (checkIOS()) {
    window.webkit.messageHandlers.eventMessageHandler.postMessage(JSON.stringify({
      event: event,
      payload: payload
    }))
  }
}

export const openPageNative = (payload) => {
  switch (payload) {
    case 'Register':
      triggerEventApp('PAGE_REDIRECT_REGISTER', {})
      break
    case 'Login':
      triggerEventApp('PAGE_REDIRECT_LOGIN', {})
      break
    case 'Index':
      triggerEventApp('PAGE_REDIRECT_TOP', {})
      break
    case 'Profile.Mypage':
      triggerEventApp('PAGE_REDIRECT_MY_PAGE', {})
      break
    case 'Profile.PointExchangeHistory':
      triggerEventApp('PAGE_REDIRECT_POINT_EXCHANGE_HISTORY', {})
      break
    case 'Profile.RecentlySeenAds':
      triggerEventApp('PAGE_REDIRECT_ADVERTISING_BROWSING_HISTORY', {})
      break
    case 'PageNotFound':
      triggerEventApp('PAGE_REDIRECT_ERROR', {})
      break
    case 'Expired':
      triggerEventApp('PAGE_REDIRECT_ERROR', {})
      break
    case 'PageError':
      triggerEventApp('PAGE_REDIRECT_ERROR', {})
      break
    case 'Offline' :
      triggerEventApp('PAGE_REDIRECT_OFFLINE', {})
      break
  }
}

export const formatDateToString = (eightDigits) => {
  eightDigits = eightDigits.replace(/\D/g, '')
  return eightDigits.slice(0, 4) + '-' + eightDigits.slice(4, 6) + '-' + eightDigits.slice(6, 8)
}

export const checkDateInvalid = (date) => {
  // check field birthday is invalid date
  date = date.replace(/\D/g, '')
  const dateString = date.slice(0, 4) + '-' + date.slice(4, 6) + '-' + date.slice(6, 8)
  // use moment to check check leap year
  const day = moment(dateString)
  return !day.isValid()
}

export const checkBirthdayInvalid = (date) => {
  return !(/^[0-9]+$/.test(date)) || date.length !== 8
}

export const removeParamsFromURL = (router, params) => {
  const query = Object.assign({}, router.currentRoute.query)

  params.forEach((param) => {
    delete query[param]
  })

  router.replace({
    query
  }).catch(() => {})
}

export const saveOneTimeParam = (param, value) => {
  sessionStorage.setItem(param, value)
}

export const saveDohBoParam = (param, value) => {
  Cookie.set(param, value, { expires: 30 })
}

const transformAttributeParam = (prefixes, data) => {
  const result = Object.entries(data).filter((item) =>
    prefixes.find((prefix) => item && item.length > 0 && item[0].includes(prefix))
  )
  return Object.fromEntries(result)
}

export const getDohBoParams = (data = Cookie.get()) => transformAttributeParam(dohBoParamPrefix, data)

export const getOneTimeParams = (data = sessionStorage) => transformAttributeParam(oneTimeParamPrefix, data)

export const removeOneTimeParams = (oneTimeParams = getOneTimeParams()) => {
  Object.keys(oneTimeParams).forEach(param => {
    sessionStorage.removeItem(param)
  })
}

export const removeDohBoParams = (dohBoParams = getDohBoParams()) => {
  Object.keys(dohBoParams).forEach((param) => {
    Cookie.remove(param)
  })
}
