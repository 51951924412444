import { list } from '@/services/category-ranking.service'

const state = {
  rankingList: []
}

const actions = {
  getList ({ commit }, params = {}) {
    return list(params).then(res => {
      if (res.data.success) {
        commit('SET_LIST', res.data.data)
      }
      return res
    }).catch(err => {
      return err.response
    })
  }
}

const mutations = {
  SET_LIST (state, payload) {
    state.rankingList = payload
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
