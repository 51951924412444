import store from '@/store'
import { getExchangeServiceNames } from '@/utils/exchange-service'

export const ExchangePointGuard = async (to, from, next) => {
  const exchangeList = await store.dispatch('pointExchange/getPointExchangeList')
  const response = exchangeList?.data?.data
  if (!response) return next({ name: 'PageNotFound' })

  const paths = to.path.split('/')
  const pathName = paths && paths.length ? paths[paths.length - 1] : ''
  const exchangeServiceNames = getExchangeServiceNames()
  const serviceName = exchangeServiceNames.find((serviceName) => serviceName === pathName)
  if (!serviceName) return next({ name: 'PageNotFound' })

  const serviceFound = response.find((service) => service.path === serviceName)
  if (!serviceFound) return next({ name: 'PageNotFound' })

  next()
}
