<template>
  <div id="app" :class="{'has-modal': flagModal}">
   <router-view />

    <!-- Loading when call API -->
    <Loading v-if="loading"/>
  </div>
</template>

<script>
import Loading from '@/components/Loading'
import { mapState } from 'vuex'
import store from '@/store'
import Cookie from 'js-cookie'

// LISTEN FCM-TOKEN FROM APP
document.addEventListener('FCM_TOKEN', (event) => {
  store.commit('auth/SET_FCM_TOKEN', event.detail.fcm_token)
})

// LISTEN TOKEN FROM APP WHEN SEND PAGE_READY IN BEFORE EACH
document.addEventListener('USER_TOKEN', (event) => {
  store.commit('auth/SET_TOKEN', event.detail.token)

  // SET COOKIE HERE BECAUSE SET IN mutations WILL BE ERROR (MAYBE)
  Cookie.set('bearer_token', event.detail.token, { expires: 7, secure: true })

  if (event.detail.token) {
    // SET USER PROFILE TO GET DATA IN FORM (EST: CHANGE-EMAIL,..)
    store.dispatch('auth/userProfile')

    // GET USER POINT TO EXCHANGE POINT(LINEPAY, QUOPAY, GPOINT, GIFTPAD)
    store.dispatch('auth/userPoint')
  }
})

export default {
  name: 'App',

  components: { Loading },

  computed: {
    ...mapState('loader', ['loading']),
    ...mapState('cart', ['flagModal']),
    ...mapState('advertises', ['detail'])
  }
}
</script>
<style lang="scss">
body {
  overflow-x: hidden;
}
#app {
  @media #{$info-phone} {
    overflow-x: hidden;
  }
}
.wrap-menu-profile {
  visibility: hidden;
  position: fixed;
  right: -100%;
  width: 90vw;
  top: 0;
  z-index: 32;
  transition: .5s;
  &.show {
    @media #{$info-phone} {
      visibility: visible;
      right: 0;
    }
  }
  .close-menu {
    position: absolute;
    top: 12px;
    right: 18px;
    color: #ffffff;
    cursor: pointer;
  }
}
.hidden-pc {
  display: none !important;
  @media #{$info-phone } {
    display: flex !important;
  }
}
</style>
