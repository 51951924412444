import {
  sendInquiry
} from '@/services/contact.service'

const initialState = {}

export const state = { ...initialState }

const getters = {}

const mutations = {}

const actions = {
  sendInquiry ({ commit }, params = {}) {
    return sendInquiry(params).then(res => {
      return res
    }).catch(err => {
      return err.response
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
