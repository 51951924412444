import { ApiService } from './api.service'
import { getOneTimeParams } from '@/utils/helpers'

const BaseUrl = '/favorite-advertises'

const payload = (params) => ({
  ...params,
  ...getOneTimeParams()
})

export function getListFavorite (params = {}) {
  return ApiService.get(`${BaseUrl}`, payload(params)).then(resp => resp)
}

export function like (params = {}) {
  return ApiService.post(`${BaseUrl}`, payload(params)).then(resp => resp)
}

export function unlike (params = {}) {
  return ApiService.delete(`${BaseUrl}`, payload(params)).then(resp => resp)
}
