import {
  getList
} from '@/services/search.service'

const state = {
  searchList: [],
  pagination: {}
}

const actions = {
  getListSearch ({ commit }, params = {}) {
    return getList(params).then(res => {
      if (res.data.success) {
        commit('SET_LIST_SEARCH', res.data.data)
        commit('SET_PAGINATION', res.data.pagination)
      }
      return true
    }).catch(_ => {
      return false
    })
  }
}

const mutations = {
  SET_LIST_SEARCH (state, payload) {
    state.searchList = payload
  },

  SET_PAGINATION (state, payload) {
    state.pagination = payload
  }
}

const getters = {
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
