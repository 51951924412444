export const EXCHANGE_POINT_SERVICE = {
  giftPad: {
    id: 1,
    name: 'giftpad',
    route: 'ExchangePoint.Giftpad'
  },
  gPoint: {
    id: 2,
    name: 'gpoint',
    route: 'ExchangePoint.Gpoint'
  },
  dotMoney: {
    id: 3,
    name: 'dotmoney',
    route: 'ExchangePoint.DotMoney'
  },
  linePay: {
    id: 4,
    name: 'linepay',
    route: 'ExchangePoint.LinePay'
  },
  quoPay: {
    id: 5,
    name: 'quopay',
    route: 'ExchangePoint.QuoPay'
  },
  auPay: {
    id: 6,
    name: 'aupay',
    route: 'ExchangePoint.AuPay'
  },
  tully: {
    id: 7,
    name: 'tully',
    route: 'ExchangePoint.Tully'
  },
  kfc: {
    id: 8,
    name: 'kfc',
    route: 'ExchangePoint.KFC'
  },
  yoshinoya: {
    id: 9,
    name: 'yoshinoya',
    route: 'ExchangePoint.Yoshinoya'
  },
  movieWalker: {
    id: 10,
    name: 'movie-gift',
    route: 'ExchangePoint.MovieWalker'
  }
}
