import { detailLanding } from '@/services/landing-page.service'

const state = {
  detailLP: {}
}

const actions = {
  getDetail ({ commit }, params = {}) {
    return detailLanding(params).then(res => {
      if (res.data.success) {
        commit('SET_DETAIL', res.data.data)
      }
    }).catch(err => {
      return err.response
    })
  }
}

const mutations = {
  SET_DETAIL (state, payload) {
    state.detailLP = payload
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
