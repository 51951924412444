const LoadScript = {
  install: function (Vue) {
    Vue.loadScript = Vue.prototype.$loadScript = function (src, async, defer, content) {
      return new Promise(function (resolve, reject) {
        if (!document || !document.body) reject(new Error('document not created'))

        let shouldAppend = false
        let el = document.querySelector('script[src="' + src + '"]')

        if (!el) {
          el = document.createElement('script')
          if (src) el.src = src
          if (async) el.async = true
          if (defer) el.defer = true
          if (content) {
            const scriptContent = document.createTextNode(content)
            el.appendChild(scriptContent)
          }
          shouldAppend = true
        } else if (el.hasAttribute('data-loaded')) {
          resolve(el)
          return
        }

        el.addEventListener('error', reject)
        el.addEventListener('abort', reject)
        el.addEventListener('load', function loadScriptHandler () {
          resolve(el)
        })

        if (shouldAppend) document.body.appendChild(el)
      })
    }

    Vue.unloadScript = Vue.prototype.$unloadScript = function (src) {
      return new Promise(function (resolve, reject) {
        const el = document.querySelector('script[src="' + src + '"]')

        if (!el) {
          reject(new Error('element not found'))
          return
        }

        document.body.removeChild(el)

        resolve()
      })
    }
  }
}

export default LoadScript
