import { getPointExchangeList } from '@/services/point-exchange.service'

const initialState = {
  services: []
}

export const state = { ...initialState }

const getters = {}

const mutations = {
  SET_LIST (state, payload) {
    state.services = payload
  }
}

const actions = {
  getPointExchangeList ({ commit }, params = {}) {
    return getPointExchangeList(params).then(res => {
      commit('SET_LIST', res.data.data)
      return res
    }).catch(err => {
      return err.response.data
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
