import { list } from '@/services/pickup.service'

const state = {
  pickupList: []
}

const actions = {
  getList ({ commit }, params = {}) {
    return list(params).then(res => {
      if (res.data.success) {
        commit('SET_LIST', res.data.data)
      }
    })
  }
}

const mutations = {
  SET_LIST (state, payload) {
    state.pickupList = payload
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
