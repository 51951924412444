import moment from 'moment'
import { PROFILE_ROUTE } from '@/enum/profile'
import { getExchangeServiceRoutes } from '@/utils/exchange-service'
import {
  removeParamsFromURL,
  saveOneTimeParam,
  getDohBoParams,
  removeDohBoParams
} from '@/utils/helpers'

export default {
  methods: {
    scrollToTop () {
      // set time out to page render all component before scroll
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }, 10)
    },

    copyText (val, $event) {
      const content = this.$refs[val]
      content.select()
      // disable input to user can't type
      content.setAttribute('disabled', true)

      // add tooltip when copied in 2s
      $event.target.children[1].classList.add('show')
      setTimeout(() => {
        $event.target.children[1].classList.remove('show')
      }, 2000)

      // copy
      document.execCommand('copy')
      // remove disable input to user copy other times
      content.removeAttribute('disabled')
    },

    checkDateBetween (from, to) {
      // Check if one date is between two dates
      const current = new Date().toJSON()
      return moment(current).isBetween(moment(from), moment(to))
    },

    defineProfile (route) {
      const exchangeServiceRoutes = getExchangeServiceRoutes()
      const profileRoutes = Object.values(PROFILE_ROUTE)
      const arrRoute = [
        'ExchangePoint',
        'ChangeProfile',
        'ChangeEmailComplete',
        'ChangeEmail',
        ...profileRoutes,
        ...exchangeServiceRoutes
      ]
      return arrRoute.includes(route)
    },

    checkDevice () {
      const isIOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
      const isSafari = (navigator.userAgent.indexOf('Safari') > -1) && !(navigator.userAgent.indexOf('Chrome') > -1)
      return isIOS || isSafari
    },

    checkWebview () {
      return !!((this.checkAndroid() || this.checkIOS()))
    },

    checkAndroid () {
      const userAgent = window.navigator.userAgent.toLowerCase()
      return !!userAgent.includes('wv')
    },

    checkIOS () {
      const userAgent = window.navigator.userAgent.toLowerCase()
      const safari = /safari/.test(userAgent)
      const ios = /iphone|ipod|ipad/.test(userAgent)
      return !!((ios && !safari))
    },

    triggerEventApp (event, payload) {
      if (this.checkAndroid()) {
        window.android.triggerEvent(JSON.stringify({
          event: event,
          payload: payload
        }))
      } else if (this.checkIOS()) {
        window.webkit.messageHandlers.eventMessageHandler.postMessage(JSON.stringify({
          event: event,
          payload: payload
        }))
      }
    },

    openPageNative (page) {
      if (this.checkWebview()) {
        switch (page) {
          case 'Register':
            this.triggerEventApp('PAGE_REDIRECT_REGISTER', {})
            break
          case 'Login':
            this.triggerEventApp('PAGE_REDIRECT_LOGIN', {})
            break
          case 'Index':
            this.triggerEventApp('PAGE_REDIRECT_TOP', {})
            break
          case 'Profile.Mypage':
            this.triggerEventApp('PAGE_REDIRECT_MY_PAGE', {})
            break
          case 'Profile.PointExchangeHistory':
            this.triggerEventApp('PAGE_REDIRECT_POINT_EXCHANGE_HISTORY', {})
            break
          case 'Profile.RecentlySeenAds':
            this.triggerEventApp('PAGE_REDIRECT_ADVERTISING_BROWSING_HISTORY', {})
            break
          case 'PageNotFound':
            this.triggerEventApp('PAGE_REDIRECT_ERROR', {})
            break
          case 'Expired':
            this.triggerEventApp('PAGE_REDIRECT_ERROR', {})
            break
          case 'PageError':
            this.triggerEventApp('PAGE_REDIRECT_ERROR', {})
            break
          case 'Offline' :
            this.triggerEventApp('PAGE_REDIRECT_OFFLINE', {})
            break
        }
      } else {
        if (page !== 'Offline') {
          this.$router.push({ name: page })
        }
      }
    },

    openUrl (url) {
      if (this.checkWebview()) {
        this.triggerEventApp('OPEN_BROWSER', { url: url })
      } else window.open(url, '_blank')
    },

    addTagScript (url) {
      const js = document.createElement('script')
      js.type = 'text/javascript'
      js.src = url
      return document.head.appendChild(js)
    },

    checkMobile () {
      const isMobile = /iPad|iPhone|iPod|Android/i.test(window.navigator.userAgent)
      return !!isMobile
    },

    removeParamsFromURL,

    saveOneTimeParam,

    getDohBoParams,

    removeDohBoParams
  }
}
