import Cookie from 'js-cookie'
import store from '@/store'
import Common from '@/mixins/common.mixin'

// Config Axios
export const axiosConfig = {
  baseURL: process.env.VUE_APP_ROOT_API,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 30000 // request timeout
}

// Config Request Interceptor
export const axiosInterceptorRequestConfig = (config) => {
  if (Cookie.get('bearer_token')) {
    config.headers.Authorization = `Bearer ${Cookie.get('bearer_token')}`
  }
  if (!Common.methods.checkWebview()) {
    store.dispatch('loader/pending')
  }
  return config
}

// Config Request Error Interceptor
export const axiosInterceptorRequestError = (error) => {
  if (!Common.methods.checkWebview()) {
    store.dispatch('loader/done')
  }
  return Promise.reject(error)
}
