import {
  registerMail,
  registerUser,
  checkToken,
  changeProfile,
  registerSNS,
  sendChangeEmail,
  checkChangeEmail,
  settingEmail,
  leave,
  exchangeHistory,
  infoStatisticCommon,
  transactionHistory,
  getGift,
  checkPhone
} from '@/services/user.service'
const initialState = {
  email: null,
  flagModalSearch: false,
  invite_code: null,
  exchangeHistory: [],
  dataGift: {},
  infoStatisticCommon: {},
  transactionHistory: [],
  pagination: [],
  pageActive: 1
}

export const state = { ...initialState }

const getters = {}

const mutations = {
  SET_EMAIL (state, payload) {
    state.email = payload
  },

  SET_INVITE_CODE (state, payload) {
    state.invite_code = payload
  },

  DISPLAY_MODAL (state, flag) {
    state.flagModalSearch = flag
  },

  SET_EXCHANGE_HISTORY (state, payload) {
    state.exchangeHistory = payload
  },

  SET_STATISTIC_COMMON (state, payload) {
    state.infoStatisticCommon = payload
  },

  SET_TRANSACTION_HISTORY (state, payload) {
    state.transactionHistory = payload
  },

  SET_PAGINATION (state, payload) {
    state.pagination = payload
  },

  SET_PAGE_ACTIVE (state, page) {
    state.pageActive = page
  },

  SET_DATA_GIFT (state, payload) {
    state.dataGift = payload
  }
}

const actions = {
  userRegisterLink ({ commit }, params = {}) {
    return registerMail(params).then(res => {
      return res
    }).catch(err => {
      return err.response
    })
  },

  userRegister ({ commit }, params = {}) {
    return registerUser(params).then(res => {
      return res
    }).catch(err => {
      return err.response
    })
  },

  changeProfile ({ commit }, params = {}) {
    return changeProfile(params).then(res => {
      return res
    }).catch(err => {
      return err.response
    })
  },

  checkTokenRegister ({ commit }, params = {}) {
    return checkToken(params).then(res => {
      commit('SET_EMAIL', res.data.data.email)
      commit('SET_INVITE_CODE', res.data.data.invite_code)
      return res
    }).catch(err => {
      return err.response.data
    })
  },

  userRegisterSNS ({ commit }, params) {
    return registerSNS(params).then(res => {
      return res
    }).catch(err => {
      return err.response
    })
  },

  userSendChangeEmail ({ commit }, params) {
    return sendChangeEmail(params).then(res => {
      return res
    }).catch(err => {
      return err.response.data
    })
  },

  userCheckChangeEmail ({ commit }, params) {
    return checkChangeEmail(params).then(res => {
      return res
    }).catch(err => {
      return err.response.data
    })
  },

  userSettingEmail ({ commit }, params) {
    return settingEmail(params).then(res => {
      return res
    }).catch(err => {
      return err.response.data
    })
  },

  userLeave ({ commit }, params) {
    return leave(params).then(res => {
      return res
    }).catch(err => {
      return err.response.data
    })
  },

  getExchangeHistory ({ commit }, params = {}) {
    return exchangeHistory(params).then(res => {
      commit('SET_EXCHANGE_HISTORY', res.data.data)
      commit('SET_PAGINATION', res.data.pagination)
      return res
    }).catch(err => {
      return err.response.data
    })
  },

  infoStatisticCommon ({ commit }) {
    return infoStatisticCommon().then(res => {
      commit('SET_STATISTIC_COMMON', res.data)
      return res
    }).catch(err => {
      return err.response.data
    })
  },

  getTransactionHistory ({ commit }, params = {}) {
    return transactionHistory(params).then(res => {
      commit('SET_TRANSACTION_HISTORY', res.data.data)
      commit('SET_PAGINATION', res.data.pagination)
      return res
    }).catch(err => {
      return err.response.data
    })
  },

  getGift ({ commit }, id) {
    return getGift(id).then(res => {
      commit('SET_DATA_GIFT', res.data.data)
      return res
    }).catch(() => {
      return false
    })
  },

  checkPhone ({ commit }, params = {}) {
    return checkPhone(params).then(res => {
      return res.data
    }).catch(err => {
      return err.response.data
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
