import Vue from 'vue'
import VueRouter from 'vue-router'
import page from './modules/page'
import store from '@/store'
import Resource from '@/components/Resource'
import {
  saveOneTimeParam,
  saveDohBoParam,
  removeOneTimeParams,
  removeDohBoParams,
  removeParamsFromURL,
  getDohBoParams,
  getOneTimeParams
} from '@/utils/helpers'
import { HEADER_TITLE } from '@/enum/title-header'
import { RESPONSE_STATUS } from '@/enum/response'
import Common from '@/mixins/common.mixin'
import Cookie from 'js-cookie'

Vue.use(VueRouter)

const routes = [
  {
    path: '/error',
    name: 'PageError',
    component: () => import('@/views/PageError/PageError.vue')
  },
  {
    path: '/',
    component: Resource,
    children: page
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
})

const beforeEach = (to, from, next) => {
  if (Common.methods.checkWebview()) {
    // LISTEN LINE_INFORMATION FROM APP TO EXCHANGE POINT LINEPAY
    document.addEventListener('LINE_INFORMATION', async (event) => {
      await store.commit('linePay/SET_LINE_TOKEN', event.detail.token)
      await store.commit('linePay/SET_POINT_EXCHANGE', event.detail.point)
    })

    // addEventListener offline
    window.addEventListener('offline', (event) => {
      Common.methods.openPageNative('Offline')
    })

    // send page_ready for app
    try {
      const headerTitle = HEADER_TITLE.find(x => x.router === to.name).title
      const headerPattern = HEADER_TITLE.find(x => x.router === to.name).pattern
      const flagAuthen = HEADER_TITLE.find(x => x.router === to.name).flag_authen
      Common.methods.triggerEventApp('PAGE_READY', {
        name: headerTitle,
        pattern: headerPattern,
        flagAuthen: flagAuthen
      })
    } catch (e) {
      console.log(e)
    }
  }

  // turn off modal when change route
  store.commit('user/DISPLAY_MODAL', false)

  if (store.state.auth.token && !Common.methods.checkWebview()) {
    if (!Object.keys(store.state.auth.profile).length) {
      store.dispatch('auth/userProfile')
    }

    // if current route is pageError -> allow logout
    if (to.name !== 'DeleteUserComplete' && from.name !== 'PageError') {
      store.dispatch('auth/userPoint')
    }
  }
  next()
}

router.beforeEach(beforeEach)

router.afterEach(async (to, from) => {
  // REPLACE QUERY WHEN USER LOGGED IN AND HAS INVITE CODE
  if (router.currentRoute.query.invite && store.state.auth.token) {
    // JUST REPLACE QUERY INVITE
    const query = Object.assign({}, router.currentRoute.query)
    delete query.invite
    router.replace({
      query
    }).catch(() => {})
  }
  // ADD QUERY WHEN USER NOT LOGGED IN AND HAS INVITE CODE
  if (from.query.invite && !store.state.auth.token) {
    router.push({
      ...to.name,
      ...to.params,
      query: {
        invite: from.query.invite
      }
    }).catch(() => {})
  }

  // detect tracking session a8
  if (to.query.a8) {
    const a8 = Cookie.get('a8_custom')
    if (!a8 || a8 !== to.query.a8) {
      // set cookie a8 in 90 day
      Cookie.set('a8_custom', to.query.a8, { expires: 90 })
    }
  }

  // handle one time params
  const oneTimeParams = getOneTimeParams(to.query)
  const hasOneTimeParam = Object.keys(oneTimeParams).length !== 0

  if (hasOneTimeParam) {
    removeOneTimeParams()
  }

  Object.entries(oneTimeParams).forEach(([param, value]) => {
    saveOneTimeParam(param, value)
  })

  // handle dohBo params
  const dohBoParams = getDohBoParams(to.query)
  const hasDohBoParam = Object.keys(dohBoParams).length !== 0
  const { token, profile } = store.state.auth

  if (!token || !profile) {
    if (hasDohBoParam) {
      removeDohBoParams()
    }

    Object.entries(dohBoParams).forEach(async ([param, value]) => {
      saveDohBoParam(param, value)
    })

    removeParamsFromURL(router, Object.keys({
      ...dohBoParams,
      ...oneTimeParams
    }))
  } else {
    if (!hasDohBoParam) {
      if (hasOneTimeParam) {
        removeParamsFromURL(router, Object.keys({
          ...oneTimeParams
        }))
      }
      return
    }

    const handleParamResponse = await store.dispatch('dohBo/handleDohBoParam', dohBoParams)

    if (handleParamResponse.status === RESPONSE_STATUS.OK) {
      removeDohBoParams(dohBoParams)
      removeParamsFromURL(router, Object.keys({
        ...dohBoParams,
        ...oneTimeParams
      }))
    } else if (hasOneTimeParam) {
      removeParamsFromURL(router, Object.keys({
        ...oneTimeParams
      }))
    }
  }
})

export default router
