import {
  auPayExchangePoint
} from '@/services/auPay.service'

const initialState = {}

export const state = { ...initialState }

const getters = {}

const mutations = {}

const actions = {
  auPayExchangePoint ({ commit }, params = {}) {
    return auPayExchangePoint(params).then(res => {
      return res
    }).catch(err => {
      return err.response.data
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
