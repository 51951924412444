import { ApiService } from './api.service'

const BaseUrl = '/auth'

export function login (params = {}) {
  return ApiService.post(`${BaseUrl}/login`, params).then((resp) => resp.data)
}

export function logout () {
  return ApiService.put(`${BaseUrl}/logout`).then((resp) => resp.data)
}

export function profile () {
  return ApiService.get(`${BaseUrl}/me?with=otp`).then((resp) => resp.data)
}

export function profileMore () {
  return ApiService.get(`${BaseUrl}/profile`).then((resp) => resp.data)
}

export function resetPassword (params = {}) {
  return ApiService.put(`${BaseUrl}/password/reset-password`, params).then((resp) => resp)
}

export function checkToken (params = {}) {
  return ApiService.get(`${BaseUrl}/password/check-token`, params).then((resp) => resp)
}

export function forgotPasswrod (params = {}) {
  return ApiService.get(`${BaseUrl}/password/send-reset-link`, params).then((resp) => resp)
}

export function redirectSNS (service, params = {}) {
  return ApiService.get(`${BaseUrl}/login-sns/${service}`, params).then(resp => resp.data)
}

export function loginSNS (service, params = {}) {
  return ApiService.post(`${BaseUrl}/login-sns/${service}`, params).then(resp => resp.data)
}

export function linePayAuthentication (params = {}) {
  return ApiService.post(`${BaseUrl}/line-authentication`, params).then(resp => resp.data)
}

export function userPoint () {
  return ApiService.get(`${BaseUrl}/user-point`).then(resp => resp.data)
}
