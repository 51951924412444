import Vue from 'vue'
import VueI18n from 'vue-i18n'
import validationMessagesJapanese from 'vee-validate/dist/locale/ja'
// import languages
import japanese from '@/languages/ja'

Vue.use(VueI18n)

const messages = {
  japanese: {
    ...japanese,
    validation: {
      ...validationMessagesJapanese.messages
    }
  }
}

const i18n = new VueI18n({
  locale: 'japanese',
  fallbackLocale: 'japanese',
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  messages
})

export default i18n
