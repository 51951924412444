import {
  confirmExchange,
  authDotMoney,
  moneyExchange,
  loginDotMoney
} from '@/services/dot-money.service'
const initialState = {
}

export const state = { ...initialState }

const getters = {}

const mutations = {}

const actions = {
  confirmExchange () {
    return confirmExchange().then(res => {
      return res
    }).catch(err => {
      return err.response.data
    })
  },

  authDotMoney () {
    return authDotMoney().then(res => {
      return res
    }).catch(err => {
      return err.response.data
    })
  },

  moneyExchange ({ commit }, params = {}) {
    return moneyExchange(params).then(res => {
      return res
    }).catch(err => {
      return err.response.data
    })
  },

  loginDotMoney () {
    return loginDotMoney().then(res => {
      return res
    }).catch(err => {
      return err.response.data
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
