import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

/* ============
 * CSS Default
 * ============
 */
// Styling
import './assets/scss/main.scss'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
// css select search
import 'vue-search-select/dist/VueSearchSelect.css'

/* ============
 * Plugins
 * ============
 */
import i18n from './plugins/i18n'
import './plugins/validation'
import './plugins/pagination'
import './plugins/vue-social-sharing'
import './plugins/vue-load-script'

/* ============
 * Utils
 * ============
 */
import './utils/filters'
import './utils/helpers'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
