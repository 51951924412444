import { ApiService } from './api.service'

const BaseUrl = '/share-sns'

export function authorizeTwitter (params = {}) {
  return ApiService.post(`${BaseUrl}/authorize`, params).then(resp => resp)
}

export function shareTwitter (params = {}) {
  return ApiService.post(`${BaseUrl}/tweet`, params).then(resp => resp)
}
