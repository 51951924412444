import {
  getListCart,
  deleteCart,
  createCart,
  updateCart,
  getCartDetail
} from '@/services/cart.service'

const initialState = {
  cartList: [],
  pagination: {},
  cartDetail: {},
  flagModal: false
}

export const state = { ...initialState }

const getters = {
  flagModal: state => state.flagModal
}

const mutations = {
  SET_LIST (state, payload) {
    state.cartList = payload
  },

  SET_CART_DETAIL (state, payload) {
    state.cartDetail = payload
  },

  SET_PAGINATION (state, payload) {
    state.pagination = payload
  },

  SET_FLAG_MODAL (state, flag) {
    state.flagModal = flag
  },

  DELETE_CART (state, cartId) {
    const index = state.cartList.findIndex((p) => p.id === cartId)
    if (index > -1) {
      state.cartList.splice(index, 1)
    }
  }
}

const actions = {
  getListCart ({ commit, dispatch }, params = {}) {
    return getListCart(params).then((res) => {
      commit('SET_LIST', res.data.data)
      if (res.data.pagination) {
        commit('SET_PAGINATION', res.data.pagination)
      }
      return res
    }).catch(err => {
      return err.response.data
    })
  },

  createCart ({ commit }, params = {}) {
    return createCart(params).then(res => {
      return res
    }).catch(err => {
      return err.response.data
    })
  },

  updateCart ({ commit }, [id, params = {}]) {
    return updateCart(id, params).then(res => {
      return res
    }).catch(err => {
      return err.response
    })
  },

  getCartDetail ({ commit, dispatch }, id) {
    return getCartDetail(id).then((res) => {
      commit('SET_CART_DETAIL', res.data.data)
      return res
    }).catch(() => {
      return false
    })
  },

  deleteCart ({ commit }, id) {
    return deleteCart(id).then((res) => {
      commit('DELETE_CART', id)

      return true
    }).catch(_ => {
      return false
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
