import Vue from 'vue'
import moment from 'moment'

Vue.filter('formatDate', function (value, format = 'YYYY-MM-DD HH:mm') {
  if (value) {
    return moment(value).locale('ja').format(format)
  }
  return ''
})

Vue.filter('formatPointNumber', function (value) {
  if (value) {
    return Intl.NumberFormat('en-US').format(value)
  }
  return ''
})

Vue.filter('findTextEnum', function (value, options = []) {
  return value ? options.find(x => x.value === value).text : '－'
})

Vue.filter('formatPostalCode', function (value) {
  return value.replace(/(\d{3})(\d{4})/, '$1-$2')
})

Vue.filter('imageEmpty', value => {
  return value || require('../assets/images/img-default.jpeg')
})

Vue.filter('numberWithCommas', function (num, sign = ',', unit = '') {
  if (!num && num !== 0) {
    return '-'
  }

  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + unit
})

Vue.filter('numberShort', function (num, sign = ',', unit = '') {
  if (!num && num !== 0) {
    return '-'
  }
  return Math.trunc(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, sign) + unit
})

Vue.filter('numberToFixed', function (num) {
  if (num % 1 === 0) {
    return num
  } else {
    const fixedLength = num.toString().split('.')[1].length
    const numFixed = fixedLength < 3 ? fixedLength : 3
    return num.toFixed(numFixed)
  }
})
