<template>
  <div :class="{ 'menu-profile': defineProfile($route.name) }"
       class="menu">
    <!-- title -->
    <div class="title">
      <p>マイページ</p>
    </div>

    <!-- list page -->
    <ul>
      <!-- option link to my page on sp -->
      <li @click="$emit('close-menu', $event)"
          class="sp">
        <router-link to="/profile/mypage">
          マイページ
          <ChevronRightIcon/>
        </router-link>
      </li>
      <li v-for="item in listMenu"
          @click="$emit('close-menu', $event)"
          :key="item.id">
        <router-link :class="{'active' : (item.childRouter.length === 0  ?
                              ($route.name === item.router) : item.childRouter.includes($route.name))}"
                     :to="{name: item.router}">
          {{ item.name }}

          <ChevronRightIcon/>
        </router-link>
      </li>
      <!-- option logout in sp -->
      <li @click="handleLogout"
          class="sp">
        ログアウト
      </li>
    </ul>
  </div>
</template>

<script>
import { ChevronRightIcon } from 'vue-feather-icons'
import { mapActions } from 'vuex'
import Common from '@/mixins/common.mixin'
import Cookie from 'js-cookie'
import { giftCodeStorageKey } from '@/enum/gift-code'

export default {
  name: 'MenuLeft',

  components: {
    ChevronRightIcon
  },

  mixins: [Common],

  data () {
    return {
      listMenu: [
        {
          id: 1,
          name: 'お知らせ',
          router: 'Profile.News.List',
          childRouter: ['Profile.News.List', 'Profile.News.Detail']
        },
        {
          id: 2,
          name: 'お気に入り',
          router: 'Profile.FavoriteAds',
          childRouter: []
        },
        {
          id: 3,
          name: '買うものリスト',
          router: 'Profile.CartList',
          childRouter: []
        },
        {
          id: 4,
          name: 'ポイント交換',
          router: 'ExchangePoint',
          childRouter: []
        },
        {
          id: 5,
          name: 'ポイント明細',
          router: 'Profile.Mypage',
          childRouter: []
        },
        {
          id: 6,
          name: 'ポイント交換履歴',
          router: 'Profile.PointExchangeHistory',
          childRouter: []
        },
        {
          id: 7,
          name: '最近見た広告',
          router: 'Profile.RecentlySeenAds',
          childRouter: []
        },
        {
          id: 8,
          name: '登録情報変更',
          router: 'ChangeProfile',
          childRouter: []
        },
        {
          id: 9,
          name: 'メール変更',
          router: 'ChangeEmail',
          childRouter: []
        },
        {
          id: 10,
          name: 'メール配信設定',
          router: 'Profile.MailSetting',
          childRouter: []
        },
        {
          id: 11,
          name: 'ギフトコード入力',
          router: 'GiftCodeInput',
          childRouter: []
        }
      ]
    }
  },

  methods: {
    ...mapActions('auth', ['userLogout']),

    async handleLogout ($event) {
      await this.userLogout()

      Cookie.remove(giftCodeStorageKey)

      if (this.$route.name !== 'Index') {
        await this.$router.push({ name: 'Index' })
      }

      // turn off menu
      this.$emit('close-menu', $event)
    }
  }
}
</script>

<style lang="scss" scoped>
.menu {
  max-width: 264px;
  width: 100%;
  border-radius: 6px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, .08);
  padding: 20px 24px 6px;
  background: #ffffff;
  position: sticky;
  top: 10px;
  float: left;
  margin-left: -24px;
  @media #{$info-phone} {
    position: static;
    float: unset;
    margin-left: 0;
    height: 100vh;
    overflow: auto;
    max-width: 100%;
    border-radius: 0;
    background: #7C934C;
    padding: 60px 40px 70px;
  }
  &.menu-profile {
    @media #{$info-phone} {
      background: #C66C44;
    }
  }
}

.title {
  font-family: $font-family-title;
  padding-bottom: 24px;
  position: relative;
  margin-bottom: 32px;
  @media #{$info-phone} {
    margin-bottom: 15px;
  }
  &:after {
    content: '';
    position: absolute;
    width: 24px;
    left: 0;
    bottom: 0;
    height: 4px;
    background: #D98F6D;
    @media #{$info-phone} {
      background: #86B12E;
    }
    .menu-profile & {
      @media #{$info-phone} {
        background: #D98F6D;
      }
    }
  }
  h3 {
    @include font-size(20px);
    color: #C66C44;
    margin-bottom: 2px;
    @media #{$info-phone} {
      font-size: 20px;
      color: #EFFFCE;
    }
    .menu-profile & {
      @media #{$info-phone} {
        color: #FFE2D5;
      }
    }
  }
  p {
    @include font-size(25px);
    @media #{$info-phone} {
      color: #ffffff;
      font-size: 18px;
    }
  }
}
ul {
  li {
    @include font-size(18px);
    margin-bottom: 8px;
    @media #{$info-phone} {
      padding: 12px 0;
      border-bottom: 1px solid #86B12E;
      margin-bottom: 0;
      color: #ffffff;
    }
    .menu-profile & {
      @media #{$info-phone} {
        border-bottom: 1px solid #D98F6D;
      }
    }
    a {
      color: #282828;
      @media #{$info-phone} {
        color: #ffffff;
        font-size: 18px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &:hover, &.active {
        color: #C66C44;
        background: rgba(198, 108, 68, 0.2);
        @media #{$info-phone} {
          color: #ffffff;
          background: transparent;
          text-decoration: underline;
        }
      }
      svg {
        display: none;
        @media #{$info-phone} {
          display: inline-block;
        }
      }
    }
  }
}
</style>
