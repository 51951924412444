import { ApiService } from './api.service'

const BaseUrl = '/line-pay'

export function getReferenceNumber (params = {}) {
  return ApiService.post(`${BaseUrl}/get-reference-number`, params).then(resp => resp)
}

export function linepayExchangePoint (params = {}) {
  return ApiService.post(`${BaseUrl}/exchange`, params).then(resp => resp)
}
