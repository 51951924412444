import { ApiService } from '@/services/api.service'
import { getOneTimeParams } from '@/utils/helpers'

const BaseUrl = '/banners'

const payload = (params) => ({
  ...params,
  ...getOneTimeParams()
})

export function list (params = {}) {
  return ApiService.get(`${BaseUrl}`, payload(params)).then((resp) => resp)
}
