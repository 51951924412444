export const RESPONSE_STATUS = {
  OK: 200,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  UNPROCESSABLE_CONTENT: 422,
  TOO_MANY_REQUEST: 429,
  INTERNAL_SERVER_ERROR: 500
}
