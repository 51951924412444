import Vue from 'vue'
import i18n from '@/plugins/i18n'
import {
  ValidationProvider, ValidationObserver, configure, extend
} from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule])
})

extend('required', {
  message: (field) => {
    return i18n.t(field) + 'を入力してください。'
  }
})

extend('max', {
  message: '{_field_}は{length}以下で入力してください。'
})

extend('min', {

  message: '{_field_}は{length}以上で入力してください。'
})

extend('email', {
  validate (value) {
    return /^[a-zA-Z0-9]{1}[a-zA-Z0-9_.+-]*@{1}[a-zA-Z0-9_-]{1,}(?:\.[a-zA-Z0-9_-]+)+$/.test(value)
  },
  message: 'メールアドレスの形式が間違っています。'
})

extend('password', {
  validate (value) {
    return /^[a-zA-Z0-9!@#$%&*()_+-=:;..?/]+$/.test(value)
  },
  message: 'パスワードには以下の文字種のみ使用可能です。半角アルファベット小文字、半角アルファベット大文字、半角数字、記号!@#$%&*()_+-=:;..?/'
})

extend('password_contain', {
  validate (value) {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/.test(value)
  },
  message: 'パスワードは半角英大文字・小文字・数字をそれぞれ1文字以上入力してください。'
})

extend('confirmed_password',
  {
    ...rules.confirmed,
    message: 'パスワードとパスワード(確認)が一致しません。'
  }
)

extend('numeric', {
  message: (field) => {
    return i18n.t(field) + 'は半角数字で入力してください。'
  }
})

extend('postCodeNum', {
  validate (value, { length }) {
    return value.length === parseInt(length)
  },
  params: ['length'],
  message: '郵便番号は{length}桁数で入力してください。'
})

extend('minmax', {
  validate (value, { min, max }) {
    return value.length >= min && value.length <= max
  },
  params: ['min', 'max'],
  message: '{_field_}は{min}文字以上{max}文字以内で入力してください。'
})

extend('only_hiragana', {
  validate (value) {
    return /^[ぁ-ゖー・]+$/.test(value)
  },
  message: '{_field_}はひらがなで入力してください。'
})

extend('only_number_halfwidth', {
  validate (value) {
    return /^[0-9]+$/.test(value)
  },
  message: '{_field_}は半角数字で入力してください。'
})

extend('halfwidth_only', {
  validate (value) {
    return /^[a-zA-Z0-9]+$/.test(value)
  },
  message (field) {
    return i18n.t(field) + 'は' + '以下で入力してください。'
  }
})

extend('number_exchange', {
  validate (value) {
    return /^[,0-9]+$/.test(value)
  },
  message: '{_field_}は半角数字で入力してください。'
})

extend('range_point', {
  validate (value) {
    const num = parseInt(value.replace(/,/g, ''))
    return num >= 500 && num <= 10000
  },
  message: '{_field_}は500から10,000までの数値で入力してください。'
})

extend('three_thousand_point', {
  validate (value) {
    const num = parseInt(value.replace(/,/g, ''))
    return num >= 500 && num <= 3000
  },
  message: '{_field_}は500から3,000までの数値で入力してください。'
})

extend('ten_thousand_point', {
  validate (value) {
    const num = parseInt(value.replace(/,/g, ''))
    return num >= 500 && num <= 10000
  },
  message: '{_field_}は500から10,000までの数値で入力してください。'
})

extend('compare_point', {
  params: ['first', 'second'],
  validate (value, { first, second }) {
    return first <= second
  },
  message: '※所持ポイントが足りません。'
})

extend('phone_minmax', {
  validate (value, { min, max }) {
    return value.length >= min && value.length <= max
  },
  params: ['min', 'max'],
  message: '{_field_}は{min}桁以上、{max}桁以下で入力してください。'
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

configure({
  defaultMessage: (field, values) => {
    const text = field.toLowerCase()
    // override the field name.
    values._field_ = i18n.t(`${text}`)

    return i18n.t(`validation.${values._rule_}`, values)
  }
})
