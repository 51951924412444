import Vue from 'vue'
import Vuex from 'vuex'

// MODULE
import loader from './modules/loader'
import auth from './modules/auth'
import user from './modules/user'
import feature from './modules/feature'
import contact from './modules/contact'
import news from './modules/news'
import favoriteAds from './modules/favorite-advertising'
import cart from './modules/cart'
import advertises from './modules/advertises'
import category from './modules/category'
import categoryRanking from './modules/category-ranking'
import banner from './modules/banner'
import pickup from './modules/pickup'
import search from './modules/search'
import afad from './modules/afad'
import exchange from './modules/exchange'
import pointExchange from './modules/point-exchange'
import dotMoney from './modules/dotMoney'
import shareSns from './modules/share-sns'
import landingPage from './modules/landing-page'
import linePay from './modules/linePay'
import quoPay from './modules/quoPay'
import auPay from './modules/auPay'
import advertisesMid from './modules/advertises-mid'
import dohBo from './modules/dohbo'
import giftCode from './modules/gift-code'

const modules = {
  loader,
  auth,
  user,
  feature,
  contact,
  news,
  favoriteAds,
  cart,
  advertises,
  category,
  categoryRanking,
  banner,
  pickup,
  search,
  afad,
  exchange,
  pointExchange,
  dotMoney,
  shareSns,
  landingPage,
  linePay,
  quoPay,
  auPay,
  advertisesMid,
  dohBo,
  giftCode
}

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'
export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules,
  strict: debug
})
