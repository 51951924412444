import store from '@/store'
import router from '@/router'
import Common from '@/mixins/common.mixin'

// Config Response Interceptor
export const axiosInterceptorResponseConfig = (response) => {
  if (!Common.methods.checkWebview()) {
    store.dispatch('loader/done')
  }
  return response
}

// Config Response Error Interceptor
export const axiosInterceptorResponseError = (error) => {
  const resError = error.toJSON()

  // show page offline native when time out (if change timeout of web, change condition below)
  if (resError.message.includes('timeout of 30000ms exceeded')) {
    Common.methods.openPageNative('Offline')
  }

  const { status } = error.response
  // Redirect to Error Page
  if (status === 401) {
    store.commit('auth/SET_LOGOUT')

    // if 401(api user_point), webview not go to login
    if (!Common.methods.checkWebview()) {
      if (router.history.current.name !== 'Login') {
        router.push({ name: 'Index' }).catch(_ => {})
      } else {
        const search = new URLSearchParams(window.location.search)
        const query = {}
        for (const [key, value] of search) {
          query[key] = value
        }

        router.push({ name: 'Login', query: query }).catch(_ => {})
      }
    }
  }
  if (status === 404) {
    if (!Common.methods.checkWebview()) {
      router.push({ name: 'PageNotFound' })
    } else {
      // add flag api err to event open page native
      store.commit('auth/SET_API_ERROR', true)
      Common.methods.openPageNative('PageNotFound')
    }
  }

  if (status === 403 || (Math.floor(status / 100) === 5)) {
    if (!Common.methods.checkWebview()) {
      router.push({ name: 'PageError' }).catch(_ => {})
    } else {
      // add flag api err to event open page native
      store.commit('auth/SET_API_ERROR', true)
      Common.methods.openPageNative('PageError')
    }
  }

  if (!Common.methods.checkWebview()) {
    store.dispatch('loader/done')
  }
  return Promise.reject(error)
}
