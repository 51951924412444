import {
  inputGiftCode,
  getDetailGiftCode,
  getDetailGiftCodeWithError
} from '@/services/gift-code.service'

const state = {
  detail: {},
  error: {}
}

const actions = {
  inputGiftCode ({ commit }, params = {}) {
    return inputGiftCode(params).then(res => {
      return res
    }).catch(err => {
      return err.response
    })
  },
  getDetailGiftCode ({ commit }, [id, params = {}]) {
    return getDetailGiftCode(id, params).then(res => {
      commit('SET_GIFT_CODE_DETAIL', res.data.data)
      return res
    }).catch(err => {
      commit('SET_GIFT_CODE_DETAIL', {})
      return err.response
    })
  },
  getDetailGiftCodeWithError ({ commit }, [code, params = {}]) {
    return getDetailGiftCodeWithError(code, params).then(res => {
      commit('SET_GIFT_CODE_DETAIL', res.data.data)
      return res
    }).catch(err => {
      commit('SET_GIFT_CODE_DETAIL', {})
      commit('SET_GIFT_CODE_ERROR', err.response.data)
      return err.response
    })
  }
}

const mutations = {
  SET_GIFT_CODE_DETAIL (state, payload) {
    state.detail = payload
  },
  SET_GIFT_CODE_ERROR (state, payload) {
    state.error = payload
  }
}

const getters = {
  giftCodeDetail: state => state.detail,
  giftCodeError: state => state.error
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
