import {
  authorizeTwitter,
  shareTwitter
} from '@/services/share-sns.service'

const state = {
  flagErrorShare: false
}

const actions = {
  authorizeTwitter ({ commit }, params) {
    return authorizeTwitter(params).then(res => {
      return res.data
    }).catch(err => {
      return err.response.data
    })
  },

  shareTwitter ({ commit }, params = {}) {
    return shareTwitter(params).then(res => {
      return res
    }).catch(err => {
      commit('ERROR_SHARE', true)
      return err.response.data
    })
  },

  setTwitterError ({ commit }, params = {}) {
    commit('ERROR_SHARE', params)
  }
}

const mutations = {
  ERROR_SHARE (state, payload) {
    state.flagErrorShare = payload
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
