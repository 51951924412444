import { ApiService } from '@/services/api.service'

const BaseUrl = '/gift-codes'

export function inputGiftCode (params = {}) {
  return ApiService.post(`${BaseUrl}/input`, params).then((resp) => resp)
}

export function getDetailGiftCode (id, params = {}) {
  return ApiService.get(`${BaseUrl}/detail/${id}`, params).then((resp) => resp)
}

export function getDetailGiftCodeWithError (code, params = {}) {
  return ApiService.get(`${BaseUrl}/detail-with-error/${code}`, params).then((resp) => resp)
}
