import Common from '@/mixins/common.mixin'
import Cookie from 'js-cookie'

export const AuthGuard = async (to, from, next) => {
  if (!Cookie.get('bearer_token') && !Common.methods.checkWebview()) {
    // webview not go to login when token null
    window.location.href = window.location.origin + '/login'
  }
  next()
}
