export const PROFILE_ROUTE = {
  pointExchangeHistory: 'Profile.PointExchangeHistory',
  myPage: 'Profile.Mypage',
  newsList: 'Profile.News.List',
  recentlySeenAds: 'Profile.RecentlySeenAds',
  newsDetail: 'Profile.News.Detail',
  favoriteAds: 'Profile.FavoriteAds',
  cartList: 'Profile.CartList',
  deleteUser: 'Profile.DeleteUser'
}
