import {
  getReferenceNumber,
  linepayExchangePoint
} from '@/services/linepay.service'
const initialState = {
  referenceNumber: null,
  lineToken: null,
  point: null
}

export const state = { ...initialState }

const getters = {}

const mutations = {
  SET_LINE_TOKEN (state, payload) {
    state.lineToken = payload
  },

  SET_POINT_EXCHANGE (state, payload) {
    state.point = payload
  },

  SET_REFERENCE_NUMBER (state, payload) {
    state.referenceNumber = payload
  }
}

const actions = {
  getReferenceNumber ({ commit }, params = {}) {
    return getReferenceNumber(params).then(res => {
      commit('SET_REFERENCE_NUMBER', res.data)
      return res.data
    }).catch(err => {
      return err.response.data
    })
  },

  linepayExchangePoint ({ commit }, params = {}) {
    return linepayExchangePoint(params).then(res => {
      return res.data
    }).catch(err => {
      return err.response.data
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
