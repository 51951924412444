import {
  login,
  logout,
  profile,
  profileMore,
  resetPassword,
  forgotPasswrod,
  checkToken,
  redirectSNS,
  linePayAuthentication,
  loginSNS,
  userPoint
} from '@/services/auth.service'
import Cookie from 'js-cookie'
import { checkWebview, removeDohBoParams, removeOneTimeParams } from '@/utils/helpers'

const initialState = {
  // IF GET TOKEN FROM COOKIE IN APP, WILL ERROR
  token: checkWebview() ? null : Cookie.get('bearer_token'),
  profile: {},
  messErr: null,
  lineRegisteredMessError: null,
  isGoogleLoginError: false,
  isYahooLoginError: false,
  isLineAuthenticated: false,
  user_point: {},
  apiErr: false,
  fcmToken: null
}

export const state = { ...initialState }

const getters = {}

const mutations = {
  SET_TOKEN (state, payload) {
    state.token = payload
    !checkWebview() && Cookie.set('bearer_token', payload, { expires: 7, secure: true })
  },
  SET_FCM_TOKEN (state, payload) {
    state.fcmToken = payload
  },
  SET_LOGOUT (state) {
    state.token = null
    state.profile = null
    Cookie.remove('bearer_token')
    removeDohBoParams()
    removeOneTimeParams()
  },
  SET_PROFILE (state, payload) {
    state.profile = payload
  },
  SET_MESSAGE_ERR (state, payload) {
    state.messErr = payload
  },
  SET_LINE_REGISTERED_MESSAGE_ERR (state, payload) {
    state.lineRegisteredMessError = payload
  },
  SET_GOOGLE_LOGIN_ERR_STATUS (state, payload) {
    state.isGoogleLoginError = payload
  },
  SET_YAHOO_LOGIN_ERR_STATUS (state, payload) {
    state.isYahooLoginError = payload
  },
  SET_LINE_AUTHENTICATED (state, payload) {
    state.isLineAuthenticated = payload
  },
  SET_USER_POINT (state, payload) {
    state.user_point = payload
  },
  SET_API_ERROR (state, payload) {
    state.apiErr = payload
  }
}

const actions = {
  userLogin ({ commit, dispatch }, params = {}) {
    return login(params).then((res) => {
      commit('SET_TOKEN', res.data.access_token)
      commit('SET_PROFILE', res.data.user)
      return res
    }).catch(err => {
      return err.response.data
    })
  },

  userLogout ({ commit }) {
    return logout().then((res) => {
      commit('SET_LOGOUT')
      return true
    }).catch(_ => {
      return false
    })
  },

  userProfile ({ commit }) {
    return profile().then((res) => {
      commit('SET_PROFILE', res.data)
      return true
    }).catch(_ => {
      return false
    })
  },

  userProfileMore ({ commit }) {
    return profileMore().then((res) => {
      commit('SET_PROFILE', res.data)
      return true
    }).catch(_ => {
      return false
    })
  },

  userResetPassword (context, params = {}) {
    return resetPassword(params).then((res) => {
      return res
    }).catch(err => {
      return err.response
    })
  },

  userForgotPassword (context, params = {}) {
    return forgotPasswrod(params).then((res) => {
      return res
    }).catch(err => {
      return err.response
    })
  },

  checkUserToken (context, params = {}) {
    return checkToken(params).then((res) => {
      return res
    }).catch(err => {
      return err.response
    })
  },

  redirectUserSNS ({ commit }, [service, params = {}]) {
    return redirectSNS(service, params).then(res => {
      return res
    }).catch(err => {
      return err.response.data
    })
  },

  linePayAuthenticate ({ commit }, [params = {}]) {
    return linePayAuthentication(params).then(res => {
      commit('SET_LINE_AUTHENTICATED', res)
      return res
    }).catch(err => err.response.data)
  },

  userLoginSNS ({ commit }, [service, params = {}]) {
    return loginSNS(service, params).then(res => {
      // check login line to exchange service(line pay)
      if (!('linepay_access_token' in res.data)) {
        res.data && 'registration_token' in res.data
          ? commit('user/SET_EMAIL', res.data.email, { root: true })
          : commit('SET_TOKEN', res.data.access_token)
      }

      return res
    }).catch(err => {
      if (err.response.data.status === 401) {
        commit('SET_MESSAGE_ERR', err.response.data.error.message)
      }
      return err.response.data
    })
  },

  userPoint ({ commit }) {
    return userPoint().then((res) => {
      commit('SET_USER_POINT', res.data)
      return true
    }).catch(_ => {
      return false
    })
  },

  setMessageError ({ commit }, message) {
    commit('SET_MESSAGE_ERR', message)
  },

  setLineRegisteredMessageError ({ commit }, message) {
    commit('SET_LINE_REGISTERED_MESSAGE_ERR', message)
  },

  setGoogleLoginErrorStatus ({ commit }, status) {
    commit('SET_GOOGLE_LOGIN_ERR_STATUS', status)
  },

  setYahooLoginErrorStatus ({ commit }, status) {
    commit('SET_YAHOO_LOGIN_ERR_STATUS', status)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
