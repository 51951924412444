import { ApiService } from '@/services/api.service'
import { getOneTimeParams } from '@/utils/helpers'

const BaseUrl = '/mid-categories'

const payload = (params) => ({
  ...params,
  ...getOneTimeParams()
})

export function getListByMid (id, params = {}) {
  return ApiService.get(`${BaseUrl}/${id}/advertises`, payload(params)).then((resp) => resp)
}
