import {
  getListByMid
} from '@/services/mid-category.service'

import {
  getListCate
} from '@/services/category.service'

const initialState = {
  advertiseListByMid: [],
  pagination: [],
  listMidTab: []
}

export const state = { ...initialState }

const getters = {}

const mutations = {
  SET_LIST (state, payload) {
    state.advertiseListByMid = payload
  },
  SET_PAGINATION (state, payload) {
    state.pagination = payload
  },
  SET_LIST_MID_TAB (state, payload) {
    state.listMidTab = payload
  },
  SET_MID_CATEGORY (state, payload) {
    state.midCategory = payload
  }
}

const actions = {
  getListAdvertisesByMid ({ commit, dispatch }, [id, params = {}]) {
    return getListByMid(id, params).then((res) => {
      commit('SET_LIST', res.data.data)
      if (res.data.pagination) {
        commit('SET_PAGINATION', res.data.pagination)
      }
      return res.data
    }).catch(err => {
      return err.response.data
    })
  },
  getListAdvertisesBySmall ({ commit, dispatch }, [id, params = {}]) {
    return getListCate(id, params).then((res) => {
      if (res.data.success) {
        commit('SET_LIST', res.data.data)
        commit('SET_PAGINATION', res.data.pagination)
      }
      return true
    }).catch(_ => {
      return false
    })
  },
  setListMidTab ({ commit }, params) {
    commit('SET_LIST_MID_TAB', params)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
