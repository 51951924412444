import {
  listTitle,
  getListCate
} from '@/services/category.service'
import { chunk } from 'lodash'
const state = {
  categoryListFooter: [],
  categoryListTitle: [],
  categoryListSidebar: [],
  categoryList: [],
  pagination: {},
  cateID: '',
  pageActive: 1
}

const actions = {
  getListTitle ({ commit }, params = {}) {
    return listTitle(params).then(res => {
      if (res.data.success) {
        commit('SET_LIST_TITLE', res.data.data)
        commit('SET_LIST_TITLE_FOOTER', res.data.data.map(item => ({ title: item.title, order: item.order, children: chunk(item.children, 3) })))
        commit('SET_LIST_TITLE_SIDEBAR', res.data.data.map(big => ({
          ...big,
          children: big.children.map(mid => ({
            ...mid,
            children: [{ id: mid.id, title: '全ての' + mid.title + 'カテゴリ', index: mid.index, level: 'mid' }, ...mid.children]
          }))
        }
        )))
      }
      return res
    }).catch(_ => {
      return false
    })
  },

  getListCate ({ commit }, [id, params = {}]) {
    return getListCate(id, params).then(res => {
      if (res.data.success) {
        commit('SET_LIST_CATE', res.data.data)
        commit('SET_PAGINATION', res.data.pagination)
        commit('SET_CATE_ID', id)
      }
      return true
    }).catch(_ => {
      return false
    })
  },
  setListCate ({ commit }, [id, payload]) {
    commit('SET_LIST_CATE', payload.data)
    commit('SET_PAGINATION', payload.pagination)
    commit('SET_CATE_ID', id)
  }
}

const mutations = {
  SET_LIST_TITLE_FOOTER (state, payload) {
    state.categoryListFooter = payload
  },
  SET_LIST_TITLE_SIDEBAR (state, payload) {
    state.categoryListSidebar = payload
  },
  SET_LIST_TITLE (state, payload) {
    state.categoryListTitle = payload
  },

  SET_LIST_CATE (state, payload) {
    state.categoryList = payload
  },

  SET_PAGINATION (state, payload) {
    state.pagination = payload
  },

  SET_CATE_ID (state, id) {
    state.cateID = id
  },

  SET_PAGE_ACTIVE (state, page) {
    state.pageActive = page
  }
}

const getters = {
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
