import { ApiService } from './api.service'
import { getOneTimeParams } from '@/utils/helpers'

const BaseUrl = '/users'

const payload = (params) => ({
  ...params,
  ...getOneTimeParams()
})

export function registerMail (params = {}) {
  return ApiService.get(`${BaseUrl}/registration/send-registration-link`, payload(params)).then(resp => resp)
}

export function registerUser (params = {}) {
  return ApiService.post(`${BaseUrl}/registration/register-manually?with=user`, params).then(resp => resp)
}

export function changeProfile (params = {}) {
  return ApiService.put(`${BaseUrl}/profile`, params).then(resp => resp)
}

export function checkToken (params = {}) {
  return ApiService.get(`${BaseUrl}/registration/check-token`, params).then(resp => resp)
}

export function profile () {
  return ApiService.get(`${BaseUrl}/me`).then((resp) => resp.data)
}

export function registerSNS (params = {}) {
  return ApiService.post(`${BaseUrl}/registration/register-sns?with=user`, params).then(resp => resp)
}

export function sendChangeEmail (params = {}) {
  return ApiService.post(`${BaseUrl}/email/reset`, params).then(resp => resp)
}

export function checkChangeEmail (params = {}) {
  return ApiService.put(`${BaseUrl}/email/update-email`, params).then(resp => resp)
}

export function settingEmail (params = {}) {
  return ApiService.put(`${BaseUrl}/mail-setting`, params).then(resp => resp)
}

export function leave (params = {}) {
  return ApiService.put(`${BaseUrl}/leave`, params).then(resp => resp)
}

export function exchangeHistory (params = {}) {
  return ApiService.get(`${BaseUrl}/mypage/exchange-history`, params).then(resp => resp)
}

export function infoStatisticCommon () {
  return ApiService.get(`${BaseUrl}/mypage/statistic-common`).then((resp) => resp.data)
}

export function transactionHistory (params = {}) {
  return ApiService.get(`${BaseUrl}/mypage/point-history`, params).then(resp => resp)
}

export function getGift (id) {
  return ApiService.put(`${BaseUrl}/mypage/exchange-history/${id}`).then(resp => resp)
}

export function checkPhone (params = {}) {
  return ApiService.get(`${BaseUrl}/check-phone`, params).then(resp => resp)
}
