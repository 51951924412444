<template>
  <div class="side-bar"
       :class="{'scroll-custom': (detectDevice === 'Windows')}"
       id="side-bar">
    <h3 class="title-filter">絞り込み</h3>
    <!-- option all category -->
    <div class="option-all">
      <span>000</span>
      <p @click="handleLink('all', 1)"
         :class="{ 'active' : itemActive === 'all' }">全てのカテゴリ</p>
    </div>

    <div class="block-category"
         v-for="(itemBig, Bigindex) in categoryListSidebar"
         :key="Bigindex">
      <!-- category big -->
      <h3>{{ itemBig.title }}</h3>

      <!-- wrap category middle and small -->
      <template v-if="itemBig.children.length > 0">
        <div v-for="(itemMid, index) in itemBig.children"
             :key="index"
             class="block-content">
          <!-- category middle -->
          <div class="wrap-cate_middle">
            <p :style="{ color: (Bigindex + 1) === 1 ? '#7C934C' : '#A54B67' }" class="middle-english">{{ displayEnglishName(itemMid.title) }}</p>
            <p class="middle-japan">{{ itemMid.title }}</p>
          </div>

          <!-- category small -->
          <ul>
            <li v-for="(itemSmall, index) in itemMid.children"
                :key="index">
              <span :style="{ color: (Bigindex + 1) === 1 ? '#7C934C' : '#A54B67' }">{{ displayNumberItem(itemSmall.index, 3) }}</span>
              <p @click="handleLink(itemSmall.id, itemBig.order, itemSmall.level)"
                 :class="{
                   'color-pink' : (Bigindex + 1) === 2,
                   'active' : itemActive === itemSmall.id
                 }" v-html="itemSmall.title">
              </p>
            </li>
          </ul>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Sidebar',

  data () {
    return {
      detectDevice: null,
      itemActive: null
    }
  },

  computed: {
    ...mapState('category', ['categoryListTitle', 'categoryListSidebar'])
  },

  watch: {
    '$route.params': {
      handler () {
        this.itemActive = this.$route.params.id
      }
    }
  },

  mounted () {
    this.detectDevice = this.getOS()
    this.itemActive = this.$route.params.id ? this.$route.params.id : ''
  },

  methods: {
    displayNumberItem (num, places) {
      const numFormat = String(num).padStart(places, '0')
      return numFormat
    },

    displayEnglishName (japanName) {
      let name = ''
      switch (japanName) {
        case 'トピックで探す':
          name = 'TOPIC'
          break
        case '無料':
          name = 'FREE'
          break
        case 'クレジットカード・キャッシング':
          name = 'CARD'
          break
        case '金融・投資・保険':
          name = 'FINANCE'
          break
        case 'エンタメ':
          name = 'ENTERTAINMENT'
          break
        case '生活・お役立ち':
          name = 'LIFE'
          break
        case 'アプリ':
          name = 'APP'
          break
        case 'ショッピング':
          name = 'SHOPPING'
          break
        case '旅行':
          name = 'TRAVEL'
          break
      }

      return name
    },

    getOS () {
      const userAgent = window.navigator.userAgent
      const platform = window.navigator?.userAgentData?.platform ?? window.navigator.platform
      const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
      const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
      const iosPlatforms = ['iPhone', 'iPad', 'iPod']
      let os = null

      if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS'
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS'
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows'
      } else if (/Android/.test(userAgent)) {
        os = 'Android'
      } else if (!os && /Linux/.test(platform)) {
        os = 'Linux'
      }

      return os
    },

    handleLink (id, typeColor, level) {
      let nameRoute = this.$route.name
      if (nameRoute !== 'CategoryList.Survey' && nameRoute !== 'CategoryList.Receipt') {
        nameRoute = 'CategoryList.Category'
      }
      const query = {}
      if (typeColor) {
        query.typeColor = String(typeColor)
      }
      if (level) {
        query.level = level
      }
      this.$router.replace({
        name: nameRoute,
        params: { id: id },
        query
      }).catch(() => {})

      this.itemActive = id
    }
  }
}
</script>

<style lang="scss" scoped>
.side-bar {
  max-width: 297px;
  width: 100%;
  padding: 0 3px 0 35px;
  margin-left: -35px;
  position: sticky;
  top: 10px;
  max-height: 100vh;
  overflow-y: auto;
  scroll-behavior: smooth;
  margin-right: 25px;
  @media only screen and (min-width: 768px) {
    overflow-y: hidden;
  }
  &:hover {
    @media only screen and (min-width: 768px) {
      overflow-y: auto;
    }
  }
  &.scroll-custom {
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 6px;
      background-color: #F5F5F5;
    }
  }
  @media #{$info-tablet-horizontal} {
    margin-left: 0;
    padding: 0 15px 0 0;
    width: 27%;
    min-width: 275px;
  }
  @media #{$info-phone} {
    max-width: 100%;
    width: 100%;
  }
  .title-filter {
    font-family: $font-family-title;
    @include font-size(28px);
    margin-bottom: 27px;
    line-height: 1;
    display: inline-block;
    @media #{$info-phone} {
      display: none;
    }
  }
  .option-all {
    display: flex;
    align-items: center;
    margin: 0 0 30px -33px;
    @media #{$info-tablet-horizontal} {
      margin-left: 0;
    }
    @media #{$info-phone} {
      margin-left: 0;
    }
    @media only screen and (min-width: 1161px) and (max-width: 1199px) {
      margin: 0 0 30px 0;
    }
    span {
      font-family: $font-label;
      @include font-size(12px);
      color: #7C934C;
      margin: 0 12px 0 0;
    }
    p {
      color: #282828;
      line-height: 1.3;
      @include font-size(16px);
      transition: .5s;
      word-break: break-all;
      cursor: pointer;
      display: inline-block;
      &.active,
      &:hover {
        transition: .5s;
        color: #7C934C;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0) 50%, rgba(124, 147, 76, 0.3) 50%);
      }
    }
  }
  .block-category {
    margin-bottom: 40px;
    h3 {
      font-family: $font-family-title;
      @include font-size(24px);
      margin-bottom: 27px;
      line-height: 1;
      @media #{$info-phone} {
        text-align: center;
        font-size: 20px;
      }
    }
    .block-content {
      .wrap-cate_middle {
        font-family: $font-family-title;
        padding: 0 10px 25px 0;
        position: relative;
        margin-bottom: 38px;
        &:after {
          content: '';
          position: absolute;
          width: 24px;
          height: 4px;
          background: #6F8784;
          left: 0;
          bottom: 0;
        }
        .middle-english {
          font-family: $font-family-title-B;
          color: $default-green;
          @include font-size(27px);
        }
        .middle-japan {
          @include font-size(18px);
        }
      }
      ul {
        margin-left: -33px;
        margin-bottom: 40px;
        @media #{$info-tablet-horizontal} {
          margin-left: 0;
        }
        @media only screen and (min-width: 1161px) and (max-width: 1199px) {
          margin-left: 0;
        }
        li {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          span {
            font-family: $font-label;
            @include font-size(12px);
            margin: 0 12px 0 0;
          }
          p {
            color: #282828;
            line-height: 1.3;
            @include font-size(16px);
            transition: .5s;
            word-break: break-all;
            cursor: pointer;
            &:hover,
            &.active {
              transition: .5s;
              color: #7C934C;
              background: linear-gradient(0deg, rgba(255, 255, 255, 0) 50%, rgba(124, 147, 76, 0.3) 50%);
            }
            &.color-pink:hover,
            &.color-pink.active {
              transition: .5s;
              color: #A54B67;
              background: linear-gradient(0deg, rgba(255, 255, 255, 0) 50%, rgba(165, 75, 103, 0.3) 50%);
            }
          }
        }
      }
    }
  }
}

</style>
