import {
  handleDohBoParam
} from '@/services/dohbo.service'

const initialState = {}

export const state = { ...initialState }

const getters = {}

const mutations = {}

const actions = {
  handleDohBoParam ({ commit }, params = {}) {
    return handleDohBoParam(params).then(res => {
      return res
    }).catch(err => {
      return err.response.data
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
