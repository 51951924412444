import {
  exchangeGpoint,
  exchangeGiftPad,
  exchangeDigitalGift
} from '@/services/exchange.service'
const initialState = {
}

export const state = { ...initialState }

const getters = {}

const mutations = {}

const actions = {
  userExchangeGpoint ({ commit }, params = {}) {
    return exchangeGpoint(params).then(res => {
      return res
    }).catch(err => {
      return err.response.data
    })
  },

  userExchangeGiftPad ({ commit }, params = {}) {
    return exchangeGiftPad(params).then(res => {
      return res
    }).catch(err => {
      return err.response.data
    })
  },

  exchangeDigitalGift ({ commit }, params = {}) {
    return exchangeDigitalGift(params).then(res => {
      return res
    }).catch(err => {
      return err.response.data
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
