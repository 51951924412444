import {
  getListFavorite,
  like,
  unlike
} from '@/services/favorite-advetising.service'

const initialState = {
  favoriteList: [],
  pagination: {}
}

export const state = { ...initialState }

const getters = {}

const mutations = {
  SET_LIST (state, payload) {
    state.favoriteList = payload
  },

  SET_PAGINATION (state, payload) {
    state.pagination = payload
  }
}

const actions = {
  getListFavorite ({ commit, dispatch }, params = {}) {
    return getListFavorite(params).then((res) => {
      commit('SET_LIST', res.data.data)
      if (res.data.pagination) {
        commit('SET_PAGINATION', res.data.pagination)
      }
      return res
    }).catch(err => {
      return err.response.data
    })
  },

  likeAds ({ commit }, params = {}) {
    return like(params).then(res => {
      return res
    }).catch(err => {
      return err.response
    })
  },

  unlikeAds ({ commit }, params = {}) {
    return unlike(params).then(res => {
      return res
    }).catch(err => {
      return err.response
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
