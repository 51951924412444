import {
  getList
} from '@/services/feature.service'

const initialState = {
  featureList: [],
  pagination: {}
}

export const state = { ...initialState }

const getters = {}

const mutations = {
  SET_LIST (state, payload) {
    state.featureList = payload
  },

  SET_PAGINATION (state, payload) {
    state.pagination = payload
  }
}

const actions = {
  getListFeature ({ commit, dispatch }, params = {}) {
    return getList(params).then((res) => {
      commit('SET_LIST', res.data.data)
      if (res.data.pagination) {
        commit('SET_PAGINATION', res.data.pagination)
      }
      return res
    }).catch(err => {
      return err.response.data
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
